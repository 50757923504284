import { FieldState, FieldValidator } from "final-form";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";

export function isEqual(a: unknown, b: unknown): boolean {
  if ((a ?? "") === (b ?? "")) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return arrayIsEqual(a, b);
  }

  if (typeof a === "object" && typeof b === "object" && a && b) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length === bKeys.length) {
      for (const key of aKeys) {
        if (!isEqual((a as any)[key], (b as any)[key])) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

function arrayIsEqual(a: any[], b: any[]): boolean {
  return (
    a === b ||
    (Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((item, i) => isEqual(item, b[i])))
  );
}

export const useComposedValidate = <T = unknown>(
  required: boolean | undefined,
  requiredError: ReactNode,
  validate: FieldValidator<T> | undefined
) => {
  const key = useMemo(() => crypto.randomUUID(), []);

  const validateRef = useRef(validate);
  useEffect(() => {
    validateRef.current = validate;
  }, [validate]);

  const { formatMessage } = useIntl();

  return useMemo(() => {
    if (!required) {
      return {
        validate: validateRef.current,
        key,
      };
    }

    return {
      validate: (value: T, allValues: object, meta?: FieldState<T>) =>
        !!value || (value as unknown) === 0
          ? validateRef.current?.(value, allValues, meta)
          : requiredError ??
            formatMessage({
              id: "required field error",
              defaultMessage: "Required",
              description: "Helper text for required field error",
            }),
      key: `${key}#req`,
    };
  }, [required, requiredError, formatMessage, key]);
};
