import {
  Breadcrumbs as MuiBreadcrumbs,
  SxProps,
  Theme,
  Typography,
  breadcrumbsClasses,
} from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import { ReactNode, useMemo } from "react";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink} />;

export interface BreadcrumbsProps {
  breadcrumbNameMap?: { [key: string]: ReactNode } | ((key: string) => ReactNode);
  crumbSx?: SxProps<Theme>;
}

const Breadcrumbs = ({ breadcrumbNameMap, crumbSx = [] }: BreadcrumbsProps) => {
  const location = useLocation();

  const paths = useMemo(
    () =>
      location.pathname
        .split("/")
        .filter((x) => x)
        .map((_, i, parts) => `/${parts.slice(0, i + 1).join("/")}`),
    [location.pathname]
  );

  const crumbs = useMemo(
    () =>
      paths.reduce(
        (arr, path) => {
          const name =
            typeof breadcrumbNameMap === "function"
              ? breadcrumbNameMap(path)
              : breadcrumbNameMap?.[path];
          if (name) {
            arr.push({ path, name });
          }
          return arr;
        },
        [] as { path: string; name: ReactNode }[]
      ),
    [paths, breadcrumbNameMap]
  );

  return (
    <MuiBreadcrumbs
      sx={{
        [`& .${breadcrumbsClasses.li}`]: {
          margin: 0,
        },
        [`& .${breadcrumbsClasses.separator}`]: {
          marginTop: 0,
          color: "primary.main",
        },
        "& li": {
          display: "flex",
        },
      }}
      maxItems={3}
      separator=">"
    >
      {crumbs.map(({ path, name }, index) => {
        return index === crumbs.length - 1 ? (
          <Typography
            component="div"
            color="text.primary"
            key={path}
            sx={[{ maxWidth: "200px" }, ...(Array.isArray(crumbSx) ? crumbSx : [crumbSx])]}
            noWrap
          >
            {name}
          </Typography>
        ) : (
          <LinkRouter
            underline="hover"
            color="primary.main"
            to={path}
            key={path}
            sx={[{ maxWidth: "200px" }, ...(Array.isArray(crumbSx) ? crumbSx : [crumbSx])]}
            noWrap
          >
            {name}
          </LinkRouter>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
