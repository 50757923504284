import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { useApi } from "../query";
import { BaseType, ID, Includes } from "../types";
import { ApiResponseOne, JsonapiError } from "./types";
import { transformJsonapi } from "./utils";

interface Include {
  [field: string]: Include | undefined | true;
}

function createCheckDup() {
  if (process.env.NODE_ENV === "development") {
    const set = new Set();
    return function (obj: unknown) {
      if (set.has(obj)) {
        return true;
      }
      set.add(obj);
    };
  } else {
    return () => false;
  }
}

function includeGraphToPaths() {
  const checkDup = createCheckDup();

  return function graphToPaths(node: Include, path = "") {
    const include: string[] = [];

    for (const [key, value] of Object.entries(node)) {
      if (value) {
        include.push(path + key);
      }
      if (typeof value === "object" && Object.keys(value).length > 0 && !checkDup(value)) {
        include.push(...graphToPaths(value, path + key + "."));
      }
    }

    return include;
  };
}

export function useGetOneSearchParams<T, S>({
  include,
  fields,
}: Pick<GetOneParams<T, S>, "include" | "fields">) {
  const includePaths = useMemo(() => {
    if (include) {
      return includeGraphToPaths()(include);
    }
  }, [include]);

  const searchParams = useMemo(() => {
    const p = new URLSearchParams();
    if (includePaths) {
      p.set("include", includePaths.join(","));
    }

    if (fields) {
      for (const [type, typeFields] of Object.entries(fields)) {
        p.set(`fields[${type}]`, typeFields.join(","));
      }
    }

    const str = p.toString();
    return str && `?${str}`;
  }, [fields, includePaths]);

  return { includePaths, searchParams };
}

export type PassThroughReactQueryOptions<T, S> = Omit<
  UseQueryOptions<T, JsonapiError, S>,
  "queryFn"
>;

export interface GetOneParams<T, S> extends PassThroughReactQueryOptions<T, S> {
  include?: T extends any[] ? Includes<T[number]> : Includes<T>;
  fields?: Record<string, string[] | readonly string[]>;
}

export default function useGetOne<T extends BaseType, S = T>(
  type: T["type"],
  id: ID,
  { include, fields, ...reactQueryOptions }: GetOneParams<T, S> = {}
) {
  const { includePaths, searchParams } = useGetOneSearchParams({
    include,
    fields,
  });

  const api = useApi();

  const fetchAndTransform = async () =>
    transformJsonapi<ApiResponseOne, T>(
      await api<ApiResponseOne>(`/${type}/${id}${searchParams}`),
      includePaths
    );

  return useQuery<T, JsonapiError, S>(
    [type, id, searchParams],
    () => fetchAndTransform(),
    reactQueryOptions
  );
}
