import {
  inputAdornmentClasses,
  inputLabelClasses,
  outlinedInputClasses,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const MbProFilterTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "emphasize",
})<TextFieldProps & { emphasize?: boolean }>(({ theme, emphasize, error }) => ({
  alignSelf: "center",
  [`& .${outlinedInputClasses.root}`]: {
    backgroundColor: "white",
    minWidth: "200px",
    maxWidth: "300px",
    borderRadius: "4px",
    alignSelf: "center",
    height: "38px",
    "&:hover fieldset": {
      transition: theme.transitions.create(["border"]),
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
    ...(emphasize && {
      "& fieldset": {
        transition: theme.transitions.create(["border"]),
        borderColor: error ? theme.palette.error.main : theme.palette.info.main,
        borderWidth: "2px",
      },
      "&:hover fieldset": {
        borderColor: error ? theme.palette.error.main : theme.palette.info.main,
      },
      "&.Mui-focused fieldset": {
        transition: theme.transitions.create(["border"]),
        borderColor: error ? theme.palette.error.main : theme.palette.info.main,
      },
    }),
  },
  [`& .${inputAdornmentClasses.root}`]: {
    transition: theme.transitions.create(["color"]),
    color: error ? theme.palette.error.main : theme.palette.primary.main,
  },
  ...(emphasize && {
    [`& .${inputLabelClasses.root}`]: {
      transition: theme.transitions.create(["color"]),
      color: error ? theme.palette.error.main : theme.palette.info.main,
    },
    [`& .${inputAdornmentClasses.root}`]: {
      transition: theme.transitions.create(["color"]),
      color: error ? theme.palette.error.main : theme.palette.info.main,
    },
  }),
}));

export default MbProFilterTextField;
