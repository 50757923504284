export default class MemoryStorage implements Storage {
  private _items: { [key: string]: string | null } = {};

  getItem(key: string) {
    return this._items[key] ?? null;
  }
  setItem(key: string, value: string | null) {
    this._items[key] = value;
  }
  removeItem(key: string) {
    delete this._items[key];
  }
  get() {
    return this._items;
  }
  get length() {
    return Object.keys(this._items).length;
  }
  key(index: number) {
    return Object.keys(this._items)[index] ?? null;
  }
  clear() {
    this._items = {};
  }
}
