import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputBase, inputBaseClasses, styled, SxProps } from "@mui/material";
import { useIntl } from "react-intl";

const StyledInput = styled(InputBase)(({ theme }) => ({
  input: {
    borderRadius: 4,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: "1px solid #ced4da",
    "&:focus": {
      borderRadius: 4,
      borderColor: theme.palette.action.focus,
      boxShadow: `0 0 5px 0.2rem ${theme.palette.action.focus}`,
    },
    padding: "5px 5px 5px 8px",
    width: "150px",
    position: "relative",
    paddingRight: "32px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    textAlign: "left",
  },
}));

export interface GlobalFilterProps {
  globalFilter: string | undefined;
  setGlobalFilter: (filterValue: string | undefined) => void;
  placement?: "left" | "right";
  placeholder?: string;
  sx?: SxProps;
}

const inputSelector = `& .${inputBaseClasses.input}`;

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  placement,
  placeholder,
  sx,
}: GlobalFilterProps) => {
  const { formatMessage } = useIntl();

  return (
    <FormControl
      sx={[
        {
          bgcolor: "background.paper",
          borderRadius: "4px",
          display: "flex",
          marginRight: "24px",
          zIndex: 1,
          width: "unset",
          minWidth: "unset",
          ...(placement ? { [placement]: 7 } : {}),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <StyledInput
        value={globalFilter ?? ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder={
          placeholder ??
          formatMessage({
            id: "label",
            defaultMessage: "Filter results",
            description: "Enhanced Table Globalfilter label",
          })
        }
        endAdornment={
          <SearchIcon color="primary" sx={{ position: "absolute", right: 7 }} fontSize="small" />
        }
        sx={{
          cursor: "auto",
          "&:hover": {
            bgcolor: "action.hover",
          },
          [inputSelector]: (theme) => ({
            transition: theme.transitions.create("width", {
              duration: theme.transitions.duration.shorter,
            }),
            width: 96,
          }),
          [`${inputSelector}:focus`]: {
            width: 168,
            bgcolor: "background.paper",
          },
          [`${inputSelector}:not(:focus)::-webkit-input-placeholder`]: {
            opacity: "0 !important",
          },
          [`${inputSelector}:not(:focus)::-moz-placeholder`]: {
            opacity: "0 !important",
          },
          [`${inputSelector}:not(:focus)::-ms-input-placeholder`]: {
            opacity: "0 !important",
          },
        }}
      />
    </FormControl>
  );
};

export default GlobalFilter;
