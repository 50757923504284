import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  styled,
  SxProps,
  TableHead,
  TableRow,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";
import { forwardRef } from "react";
import { HeaderGroup } from "react-table";
import getCustomColumnProps from "./getCustomColumnProps";

const SpanTypography = (props: TypographyProps<"div">) => (
  <Typography {...props} component={"div"} />
);

const HeaderCellContentWrapper = styled(SpanTypography)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  padding: theme.spacing(0, 1.5, 0, 2),
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const TableHeader = <Type extends object = {}>(
  {
    headerGroups,
    enableSorting,
    sx = [],
  }: {
    headerGroups: HeaderGroup<Type>[];
    enableSorting?: boolean;
    sx?: SxProps<Theme>;
  },
  ref: any
) => (
  <TableHead
    component="div"
    ref={ref}
    sx={[
      {
        display: "flex",
        alignItems: "center",
        "@media (min-width: 600px)": {
          minHeight: "unset",
          height: "30px",
        },
        "@media (min-width: 0px) and (orientation: landscape)": {
          minHeight: "unset",
          height: "30px",
        },
        bgcolor: "background.default",
        zIndex: 1,
        boxShadow: (theme) =>
          `inset 0px -1px 0px 0px ${theme.palette.divider}, 0px 1px 2px ${theme.palette.divider}`,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {headerGroups.map(({ getHeaderGroupProps, headers }) => (
      //eslint-disable-next-line react/jsx-key
      <TableRow
        {...getHeaderGroupProps()} // includes a `key` prop
        component="div"
        sx={{
          bgcolor: "transparent",
        }}
      >
        {headers.map((column, index) => (
          //eslint-disable-next-line react/jsx-key
          <Box
            {...column.getHeaderProps([
              ...getCustomColumnProps(column),
              enableSorting ? column.getSortByToggleProps() : {},
            ])}
            sx={[
              index !== headers.length - 1 && {
                borderRightWidth: "1px",
                borderRightStyle: "solid",
                borderRightColor: "divider",
              },
            ]}
          >
            <Box sx={{ display: "flex", maxWidth: "100%" }}>
              <HeaderCellContentWrapper noWrap>
                {column.render("Header")}
                {enableSorting &&
                  (column.isSorted ? (
                    column.isSortedDesc ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    )
                  ) : (
                    ""
                  ))}
              </HeaderCellContentWrapper>
            </Box>
          </Box>
        ))}
      </TableRow>
    ))}
  </TableHead>
);

export default forwardRef(TableHeader);
