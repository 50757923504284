import { IJSONObject } from "@mb-pro-ui/jsonapi-types";
import { useCallback } from "react";
import { useMutation, UseMutationOptions } from "react-query";
import { useApi, useBackendSchema } from "../query";
import { BaseType, Type } from "../types";
import { ApiResponseOne, JsonapiError } from "./types";
import { transformJsonapi, transformValues } from "./utils";

type UseCreateOptions<T> = UseMutationOptions<T, JsonapiError, IJSONObject> & {
  schemaTimeout?: number;
};

export default function useCreate<T extends BaseType>(
  fullType: Type<T["type"]>,
  { schemaTimeout = 5000, ...options }: UseCreateOptions<T> = {}
) {
  const api = useApi();
  const { waitFor } = useBackendSchema(fullType);

  const createItem = useCallback(
    async (data: IJSONObject) => {
      const schema = await waitFor(schemaTimeout);

      const response = await api<ApiResponseOne>(`/${fullType}`, {
        method: "post",
        headers: {
          Accept: "application/vnd.api+json, application/json",
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify({ data: transformValues(schema, data) }),
      });

      return transformJsonapi(response);
    },
    [api, fullType, waitFor, schemaTimeout]
  );

  return useMutation<T, JsonapiError, IJSONObject>(createItem, options);
}
