import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import React, { forwardRef } from "react";

export type ButtonProps<C extends React.ElementType> = MuiButtonProps<
  C,
  {
    mode?: "primary" | "secondary" | "warning" | "info";
    component?: C;
  }
>;

const Button = forwardRef(
  <C extends React.ElementType>(
    { mode = "primary", sx = [], ...props }: ButtonProps<C>,
    ref: any
  ) => (
    <MuiButton
      ref={ref}
      size="small"
      variant={mode === "secondary" ? "outlined" : "contained"}
      disableElevation
      sx={[
        mode === "warning" && {
          bgcolor: "warning.main",
          color: "warning.contrastText",
          "&:hover": {
            bgcolor: "warning.dark",
          },
        },
        mode === "info" && {
          bgcolor: "info.main",
          color: "info.contrastText",
          "&:hover": {
            bgcolor: "info.dark",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
);

export default Button;
