import { useCallback, useEffect, useState } from "react";
import appStorage from "./app-storage";

export type UseStorageResult<T> = [value: T | null, setValue: (value: T | null) => void];

function useStorage<T>(
  key: string,
  defaultValue: T
): [value: T, setValue: (value: T | null) => void];
function useStorage<T>(key: string): UseStorageResult<T>;

function useStorage<T>(key: string, defaultValue?: T): UseStorageResult<T> {
  const [value, setValueInMemory] = useState<T | null>(
    () => appStorage.get<T>(key) ?? defaultValue ?? null
  );

  useEffect(() => {
    function handleStorageChange() {
      setValueInMemory(appStorage.get<T>(key));
    }

    appStorage.on(handleStorageChange);
    return () => {
      appStorage.off(handleStorageChange);
    };
  }, [key, setValueInMemory]);

  const setValue = useCallback(
    (newValue) => {
      if (newValue === null) {
        appStorage.remove(key);
      } else {
        appStorage.set(key, newValue);
      }
      appStorage.save();
      setValueInMemory(newValue);
    },
    [key, setValueInMemory]
  );

  return [value, setValue];
}

export default useStorage;
