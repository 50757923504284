import { IErrorObject } from "@mb-pro-ui/jsonapi-types";
import { useMemo } from "react";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";

interface L12n {
  key: string;
  params?: Params;
}

interface Params {
  [key: string]: string;
}

export interface JsonapiErrorMessageProps {
  error: IErrorObject;
  backendMessages: Record<string, MessageDescriptor>;
}

export function localizeErrorMessage(
  formatMessage: IntlShape["formatMessage"],
  backendMessages: Record<string, MessageDescriptor>,
  error: IErrorObject
) {
  const l12n = error.meta?.l12n as unknown as L12n | undefined;
  if (!l12n || !backendMessages[l12n.key]) {
    return (
      error.detail ??
      formatMessage({
        defaultMessage: "Something went wrong",
      })
    );
  }

  const localizedParams: Record<string, string> = {};

  for (const [name, id] of Object.entries(l12n.params ?? {})) {
    if (backendMessages[id]) {
      localizedParams[name] = formatMessage(backendMessages[id]);
    } else {
      localizedParams[name] = id;
    }
  }

  return formatMessage(backendMessages[l12n.key], localizedParams);
}

const JsonapiErrorMessage = ({ error, backendMessages }: JsonapiErrorMessageProps) => {
  const { formatMessage } = useIntl();
  const errorMessage = useMemo(
    () => localizeErrorMessage(formatMessage, backendMessages, error),
    [error, formatMessage, backendMessages]
  );

  return <span data-testid="error-message">{errorMessage}</span>;
};

export default JsonapiErrorMessage;
