import BlockIcon from "@mui/icons-material/Block";
import { styled, SvgIconProps, SxProps, Theme, Typography } from "@mui/material";
import { ComponentType, ReactNode } from "react";

const PlaceholderRoot = styled("div")(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.action.disabled,
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export type PlaceholderIcon<P extends object = {}> = ComponentType<
  Partial<P> & {
    style?: SvgIconProps["style"];
  }
>;

export interface EmptyPlaceholderProps<P extends object = {}> {
  message?: ReactNode;
  Icon?: PlaceholderIcon<P>;
  iconProps?: Partial<P>;
  iconSize?: number;
  sx?: SxProps<Theme>;
}

const EmptyPlaceholder = <P extends object = {}>({
  message,
  Icon = BlockIcon,
  iconProps = {},
  iconSize = 100,
  sx,
}: EmptyPlaceholderProps<P>) => (
  <PlaceholderRoot sx={sx}>
    <Icon style={{ fontSize: iconSize }} {...iconProps} />
    {typeof message === "string" ? <Typography>{message}</Typography> : message}
  </PlaceholderRoot>
);

export default EmptyPlaceholder;
