import { SxProps } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";
import { Field, FieldRenderProps, UseFieldConfig } from "react-final-form";
import SelectInput, { SelectProps as SelectInputProps } from "../Select";
import { useComposedValidate } from "./utils";

type PassedThroughProps<V extends string, T extends { [k in V]: string | number }> = Omit<
  SelectInputProps<V, T>,
  | "name"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "value"
  | "checked"
  | "error"
  | "defaultValue"
  | "input"
  | "valueKey"
>;

export interface SelectProps<V extends string, T extends { [k in V]: string | number }>
  extends PassedThroughProps<V, T>,
    UseFieldConfig<T[V]> {
  name: string;
  valueKey?: V;
  requiredError?: ReactNode;
  highlightDirty?: boolean;
  outerSx?: SxProps;
  key?: React.Key;
}

type SelectAdapterProps<
  V extends string,
  T extends { [k in V]: string | number },
> = FieldRenderProps<T[V]> & PassedThroughProps<V, T> & { valueKey: V };

const SelectAdapter = <V extends string, T extends { [k in V]: string | number }>({
  input,
  meta: { dirty, error },
  className,
  label,
  readOnly,
  helperText,
  highlightDirty = true,
  sx = [],
  ...rest
}: SelectAdapterProps<V, T>) => (
  <SelectInput
    label={label ?? input.name}
    className={clsx(className, dirty && "Mbp-dirty")}
    error={!!error}
    helperText={error || helperText}
    readOnly={readOnly}
    sx={[
      dirty &&
        highlightDirty && {
          bgcolor: "highlight.dirty",
          "&:hover": { bgcolor: "highlight.dirty" },
          "&.Mui-focused": { bgcolor: "highlight.dirty" },
        },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
    {...input}
  />
);

const Select = <
  V extends string,
  T extends { [k in V]: string | number } = { [k in V]: string | number },
>({
  validate,
  required,
  requiredError,
  valueKey = "value" as V,
  ...rest
}: SelectProps<V, T>) => (
  <Field<T[V], HTMLElement, T[V], SelectAdapterProps<V, T>>
    required={required}
    component={SelectAdapter}
    valueKey={valueKey}
    {...useComposedValidate(required, requiredError, validate)}
    {...rest}
  />
);

export default Select;
