import { useCallback, useEffect, useState } from "react";

function useStorageState<T>(
  key: string,
  defaultValue: T,
  session: boolean = false,
  parseStoredValue: (value: string) => T = JSON.parse
): [state: T, setState: (value: T | null) => void] {
  const storage = session ? sessionStorage : localStorage;

  const [state, setStateInner] = useState(() => {
    const item = storage.getItem(key);
    if (item) {
      return parseStoredValue(item);
    } else {
      return defaultValue;
    }
  });

  const setStateOuter = useCallback(
    (newValue) => {
      if (newValue === null) {
        storage.remove(key);
      } else {
        storage.setItem(key, JSON.stringify(newValue));
      }
      setStateInner(newValue);
    },
    [key, storage]
  );

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === key) {
        if (event.newValue === null) {
          localStorage.removeItem(key);
        } else {
          setStateInner(parseStoredValue(event.newValue));
        }
      }
    },
    [key, parseStoredValue]
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [handleStorageChange]);

  return [state ?? defaultValue, setStateOuter];
}

export default useStorageState;
