import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/system";
import { PropsWithChildren } from "react";

export type FullPageLayoutProps = PropsWithChildren<{
  sx?: SxProps<Theme>;
}>;

const FullPageLayout = ({ children, sx = [] }: FullPageLayoutProps) => (
  <Box
    sx={[
      { display: "flex", flexDirection: "column", height: "100vh" },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
  </Box>
);

export default FullPageLayout;
