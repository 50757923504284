import { styled, SxProps, Theme } from "@mui/material";
import clsx from "clsx";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import useDebounce from "../utils/useDebounce";

const StyledEditor = styled(
  ({ wrapperClassName, className, ...props }: EditorProps & { className?: string }) => (
    <Editor wrapperClassName={clsx(wrapperClassName, className)} {...props} />
  )
)({
  "& .rdw-editor-toolbar": {
    marginBottom: 0,
    backgroundColor: "transparent",
    borderTopWidth: "5px",
    borderRightWidth: "5px",
    borderLeftWidth: "5px",
    borderTopColor: "rgba(0, 0, 0, 0.06)",
    borderRightColor: "rgba(0, 0, 0, 0.06)",
    borderBottomColor: "rgba(0, 0, 0, 0.06)",
  },
  "& .rdw-editor-main": {
    marginTop: "0",
    minHeight: "290px",
    backgroundColor: `rgba(0, 0, 0, 0.06)`,
    borderRadius: "4px",
    padding: "16px",
  },
});

type PassedThroughProps = Omit<EditorProps, "editorState" | "onEditorStateChange">;

export interface RichEditorAdapterProps extends PassedThroughProps, FieldRenderProps<string> {
  highlightDirty?: boolean;
  sx?: SxProps<Theme>;
}

const editorStateFromHTML = (value: string) =>
  EditorState.createWithContent(
    ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks)
  );

const htmlFromEditorState = (state: EditorState) =>
  draftToHtml(convertToRaw(state.getCurrentContent()));

const RichEditorAdapter = ({
  input: { value, onChange },
  meta: { dirty },
  highlightDirty = true,
  sx = [],
  readOnly,
  ...rest
}: RichEditorAdapterProps) => {
  const [editorState, setEditorState] = useState(editorStateFromHTML(value));
  const debouncedState = useDebounce(editorState, 50);

  useEffect(() => {
    setEditorState((state) =>
      htmlFromEditorState(state) === value ? state : editorStateFromHTML(value)
    );
  }, [value]);

  useEffect(() => {
    onChange(htmlFromEditorState(debouncedState));
  }, [onChange, debouncedState]);

  return (
    <StyledEditor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      stripPastedStyles={true}
      sx={[
        !!dirty &&
          highlightDirty && {
            "& .rdw-editor-main": { bgcolor: "highlight.dirty" },
          },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      readOnly={readOnly}
      {...rest}
    />
  );
};

export type RichEditorProps = FieldProps<string, RichEditorAdapterProps>;

export function normalizeHtml(html?: string | null) {
  return htmlFromEditorState(editorStateFromHTML(html ?? ""));
}

function isEqual(a: any, b: any) {
  return a === b || normalizeHtml(a) === normalizeHtml(b);
}

const RichEditor = (props: RichEditorProps) => (
  <Field component={RichEditorAdapter} isEqual={isEqual} {...props} />
);

export default RichEditor;
