import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import { Field, FieldRenderProps, UseFieldConfig } from "react-final-form";
import TextFieldInput, { TextFieldProps as TextFieldInputProps } from "../TextField";
import useDebounce from "../utils/useDebounce";
import { useComposedValidate } from "./utils";

type PassedThroughProps = Omit<
  TextFieldInputProps,
  | "name"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "value"
  | "checked"
  | "error"
  | "defaultValue"
  | "type"
  | "component"
>;

export interface TextFieldProps extends PassedThroughProps, UseFieldConfig<unknown> {
  name: string;
  requiredError?: ReactNode;
  highlightDirty?: boolean;
}

const TextFieldAdapter = ({
  input,
  meta: { dirty, error, touched },
  className,
  label,
  readOnly,
  helperText,
  highlightDirty = true,
  sx = [],
  hiddenLabel,
  ...rest
}: FieldRenderProps<unknown> & PassedThroughProps) => (
  <TextFieldInput
    label={!hiddenLabel && (label ?? input.name)}
    className={clsx(className, dirty && "Mbp-dirty")}
    error={!!error && touched}
    helperText={((touched && error) || helperText) ?? ""}
    readOnly={readOnly}
    sx={[
      dirty && !error && highlightDirty && { bgcolor: "highlight.dirty" },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
    {...input}
  />
);

const isEqualText = (a: unknown, b: unknown) =>
  a === 0 || b === 0 || (a && b) ? a === b : !a && !b;

const TextField = ({
  validate,
  required,
  isEqual = isEqualText,
  requiredError,
  ...rest
}: TextFieldProps) => (
  <Field
    required={required}
    isEqual={isEqual}
    component={TextFieldAdapter}
    {...useComposedValidate(required, requiredError, validate)}
    {...rest}
  />
);

export default TextField;

const parseNumeric = (value: string | number | null | undefined) => {
  if (typeof value === "number") {
    return value;
  }
  if (!value) {
    return null;
  }
  const n = Number(value.replace(",", "."));
  if (Number.isNaN(n)) {
    return value;
  }
  return n;
};

const formatNumeric = (value: unknown) => {
  if (typeof value === "number") {
    return value.toString();
  }
  if (!value) {
    return "";
  }
  return value;
};

const isEqualNumeric = (
  a: string | number | null | undefined,
  b: string | number | null | undefined
) => formatNumeric(a) === formatNumeric(b);

export const NumericField = (props: TextFieldProps) => (
  <TextField
    type="number"
    parse={parseNumeric}
    format={formatNumeric}
    isEqual={isEqualNumeric}
    sx={{
      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "input[type=number]": {
        MozAppearance: "textfield",
      },
    }}
    {...props}
  />
);

const ColorFieldAdapter = ({
  input: { value, onChange, ...input },
  meta: { dirty, error },
  className,
  label,
  readOnly,
  helperText,
  highlightDirty = true,
  sx = [],
  ...rest
}: FieldRenderProps<unknown> & PassedThroughProps) => {
  const [liveValue, setLiveValue] = useState<any>(value || "#000000");
  const debouncedValue = useDebounce(liveValue, 50);

  useEffect(() => setLiveValue(value), [value]);
  useEffect(() => onChange(debouncedValue), [onChange, debouncedValue]);

  return (
    <TextFieldInput
      label={label ?? input.name}
      className={clsx(className, dirty && "Mbp-dirty")}
      error={!!error}
      helperText={(error || helperText) ?? ""}
      readOnly={readOnly}
      sx={[
        dirty && !error && highlightDirty && { bgcolor: "highlight.dirty" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      type="color"
      onChange={(e) => setLiveValue(e.target.value)}
      value={liveValue}
      {...rest}
      {...input}
    />
  );
};

export const ColorField = ({
  validate,
  required,
  isEqual = isEqualText,
  requiredError,
  ...rest
}: TextFieldProps) => (
  <Field
    required={required}
    isEqual={isEqual}
    component={ColorFieldAdapter}
    {...useComposedValidate(required, requiredError, validate)}
    {...rest}
  />
);
