import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  Drawer as MuiDrawer,
  Portal,
  Toolbar,
  lighten,
  styled,
} from "@mui/material";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Route, Switch } from "react-router";
import { NavLink, NavLinkProps, useRouteMatch } from "react-router-dom";
import {
  Drawer,
  DrawerHeader,
  MonitoringbookPro,
  SideBarOption,
  SideBarProps,
  SidebarIcon,
  SidebarText,
  WithTooltip,
  classes,
} from "./SideBarV2";

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
  [`&.${classes.active}`]: {
    "& > div": {
      backgroundColor: lighten(theme.palette.primary.light, 0.7),
      boxShadow: `3px 0px 0px ${theme.palette.primary.main} inset`,
    },
    "& > div > div": {
      color: theme.palette.primary.main,
    },
    "& > div:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.7),
    },
  },
}));

const SideBar = ({
  options,
  footer,
  width,
  anchor,
  open,
  onSidebarOpen,
  onSidebarClose,
  header,
  backLink,
  closedWidth,
  variant,
  buttonContainer,
}: SideBarProps) => {
  const { url, path } = useRouteMatch();
  const { formatMessage } = useIntl();
  const closed = !open;

  const drawerContent = (
    <>
      {variant !== "temporary" && <Toolbar />}
      <DrawerHeader anchor={anchor}>
        {open ? (
          <>
            {header || <MonitoringbookPro />}
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={onSidebarClose}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Box sx={{ paddingLeft: (theme) => theme.spacing(0.5) }}>
            <IconButton color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Box>
        )}
      </DrawerHeader>
      <Divider />
      <List sx={{ paddingY: 0, height: "100%" }}>
        <Switch>
          {options.map((option) =>
            option.subPages ? (
              <Route path={option.subPages.map((op) => `${path}${op.link}`)} key={option.link}>
                <StyledNavLink exact to={url} activeClassName={classes.active}>
                  <ListItem>
                    <SidebarIcon>
                      <ArrowBackIcon />
                    </SidebarIcon>
                    <SidebarText
                      primary={
                        <FormattedMessage
                          defaultMessage="Back"
                          description="Submenu go back link"
                        />
                      }
                    />
                  </ListItem>
                  <Divider />
                </StyledNavLink>
                {option.subPages.map(
                  ({ link, name, classname, icon, absolute, divider, title }, index) => (
                    <Fragment key={link}>
                      {title && (
                        <>
                          {index !== 0 && <Divider />}
                          <FormHelperText sx={{ mx: 1 }}>{open ? title : " "}</FormHelperText>
                        </>
                      )}
                      <StyledNavLink
                        to={absolute ? `${url}` : `${url}${link}`}
                        className={classname}
                        activeClassName={classes.active}
                        onClick={variant === "temporary" ? onSidebarClose : undefined}
                      >
                        {divider === "top" && <Divider />}
                        <ListItem button>
                          <WithTooltip
                            component={<SidebarIcon>{icon || <InboxIcon />}</SidebarIcon>}
                            title={name}
                            open={closed}
                          />
                          <SidebarText primary={name} />
                        </ListItem>
                        {divider === "bottom" && <Divider />}
                      </StyledNavLink>
                    </Fragment>
                  )
                )}
              </Route>
            ) : null
          )}
          <Route exact path="">
            {backLink && (
              <StyledNavLink
                exact
                to={backLink}
                activeClassName={classes.active}
                onClick={variant === "temporary" ? onSidebarClose : undefined}
              >
                <ListItem>
                  <SidebarIcon>
                    <ArrowBackIcon />
                  </SidebarIcon>
                  <SidebarText
                    primary={formatMessage({
                      defaultMessage: "Back",
                      description: "Submenu go back link",
                    })}
                  />
                </ListItem>
                <Divider />
              </StyledNavLink>
            )}
            {options.map(
              ({ link, classname, name, subPages, icon, absolute, divider, title }, i) => (
                <Fragment key={link}>
                  {title && (
                    <>
                      {i !== 0 && <Divider />}
                      <FormHelperText sx={{ mx: 1 }}>{open ? title : " "}</FormHelperText>
                    </>
                  )}
                  <StyledNavLink
                    exact
                    to={absolute ? `${link}` : `${url}${link}`}
                    activeClassName={classes.active}
                    className={classname}
                    onClick={variant === "temporary" && !subPages ? onSidebarClose : undefined}
                  >
                    {divider === "top" && <Divider />}
                    <ListItem
                      button
                      sx={{
                        "&:hover": {
                          "& > svg": {
                            display: "block",
                          },
                        },
                      }}
                    >
                      <WithTooltip
                        component={<SidebarIcon>{icon || <InboxIcon />}</SidebarIcon>}
                        title={name}
                        open={closed}
                      />
                      <SidebarText primary={name} />
                      {subPages ? (
                        <ArrowForwardIcon
                          sx={{
                            display: "none",
                            color: "grey.300",
                          }}
                        />
                      ) : null}
                    </ListItem>
                    {divider === "bottom" && <Divider />}
                  </StyledNavLink>
                </Fragment>
              )
            )}
          </Route>
        </Switch>
      </List>
      {footer && (
        <Box
          sx={{
            paddigX: 2,
            paddingY: 1,
            boxShadow: (theme) => `3px 0px 0px ${theme.palette.primary.dark} inset`,
            justifySelf: "flex-end",
            bgcolor: "primary.light",
            color: "primary.contrastText",
          }}
        >
          {footer}
        </Box>
      )}
    </>
  );

  if (variant === "temporary") {
    return (
      <>
        {variant === "temporary" && buttonContainer ? (
          <Portal container={buttonContainer}>
            {open ? (
              <IconButton onClick={onSidebarClose}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton onClick={onSidebarOpen}>
                <MenuIcon />
              </IconButton>
            )}
          </Portal>
        ) : null}
        <MuiDrawer
          anchor={anchor ?? "left"}
          variant="temporary"
          open={open}
          sx={{ zIndex: 9999 }}
          onClose={onSidebarClose}
        >
          <Box sx={{ width }}>{drawerContent}</Box>
        </MuiDrawer>
      </>
    );
  }

  return (
    <Drawer
      anchor={anchor ?? "left"}
      variant={variant ?? "permanent"}
      open={open}
      width={width}
      closedWidth={closedWidth}
    >
      {drawerContent}
    </Drawer>
  );
};

export type { SideBarOption };
export default SideBar;
