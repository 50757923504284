import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, IconButton, styled, SvgIcon, Toolbar, Typography } from "@mui/material";
import {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useResizeObserver } from "../useScrollbarSize";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.info.main,
  "&:hover": {
    backgroundColor: "unset",
  },
}));

export interface FilterViewsRenderProps {
  setClearable: (clearable: boolean) => void;
  onClearRef: MutableRefObject<(() => void) | undefined>;
}

export interface CollapsableFilterWrapperProps {
  children?: (options: FilterViewsRenderProps) => ReactNode;
  style?: CSSProperties;
  title?: ReactNode;
}

const CollapsableFilterWrapper = ({
  children: renderFilters,
  style,
  title,
}: CollapsableFilterWrapperProps) => {
  const { formatMessage } = useIntl();

  const [clearable, setClearable] = useState<boolean>();
  const onClearRef = useRef<() => void>();

  const [filterHeader, filterHeaderRef] = useState<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const collapsedHeaderHeight = title === false ? 64 : 54 + 24 + 1;

  const [expandedHeaderHeight, setExpandedHeaderHeight] = useState(collapsedHeaderHeight);

  useLayoutEffect(() => {
    if (filterHeader) {
      setExpandedHeaderHeight(filterHeader.getBoundingClientRect().height);
    }
  }, [filterHeader]);

  useResizeObserver(filterHeader, (e) => setExpandedHeaderHeight(e.borderBoxSize[0].blockSize));

  const filterIsExpandable = expandedHeaderHeight > collapsedHeaderHeight;

  title = title ?? formatMessage({ defaultMessage: "Advanced search:" });

  return (
    <>
      <div
        style={{
          height: `${isExpanded ? expandedHeaderHeight : collapsedHeaderHeight}px`,
          transition: "height 0.3s ease-in-out",
          overflow: "hidden",
          position: "relative",
          ...style,
        }}
      >
        <Toolbar
          sx={[
            {
              alignItems: "normal",
              display: "flex",
              columnGap: "15px",
              flexWrap: "wrap",
              "@media (min-width: 600px)": {
                paddingRight: filterIsExpandable ? "87px" : "47px",
              },
              "@media (min-width: 0px) and (orientation: landscape)": {
                paddingRight: filterIsExpandable ? "87px" : "47px",
              },
            },
            title === false && {
              "& > *": {
                marginY: "13px",
              },
            },
          ]}
          ref={filterHeaderRef}
        >
          {title === false ? null : (
            <Box
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                minWidth: "100%",
              }}
            >
              {typeof title === "string" ? (
                <Typography
                  variant="caption"
                  color="text.primary"
                  sx={{ fontWeight: "bold", width: "100%", flex: 1 }}
                >
                  {title}
                </Typography>
              ) : (
                title
              )}
            </Box>
          )}
          {renderFilters?.({ setClearable, onClearRef })}
        </Toolbar>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: title === false ? 0 : "12px",
            height: `${collapsedHeaderHeight}px`,
            display: "flex",
            alignItems: "center",
            paddingRight: "24px",
          }}
        >
          <StyledIconButton
            size="small"
            disableRipple
            onClick={onClearRef?.current}
            disabled={!clearable}
          >
            <SvgIcon fontSize={"small"}>
              <ClearIcon />
            </SvgIcon>
          </StyledIconButton>
          {filterIsExpandable && (
            <StyledIconButton
              size="small"
              disableRipple
              onClick={() => setIsExpanded((previous) => !previous)}
              sx={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(360deg)",
                transition: "transform 0.2s linear",
              }}
            >
              <ExpandMoreIcon color="primary" />
            </StyledIconButton>
          )}
        </Box>
      </div>
      <Divider />
    </>
  );
};

export default CollapsableFilterWrapper;
