import { ColumnInstance, HeaderGroup, TableHeaderProps } from "react-table";

export default function getCustomColumnProps(
  col: ColumnInstance<any> | HeaderGroup<any>
): Partial<TableHeaderProps>[] {
  return col.id === "action" || col.id === "fix" || col.id === "selection"
    ? [
        {
          style: {
            flex: "unset",
            alignItems: "center",
            display: "flex",
          },
        },
      ]
    : [];
}
