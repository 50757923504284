import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import scrollbarWidth from "./scrollbarWidth";

export const useLatest = <T extends any>(current: T) => {
  const storedValue = useRef(current);
  useEffect(() => {
    storedValue.current = current;
  });
  return storedValue;
};

function createResizeObserver() {
  let ticking = false;
  let allEntries: ResizeObserverEntry[] = [];

  const callbacks: Map<any, Array<UseResizeObserverCallback>> = new Map();

  const observer = new ResizeObserver((entries: ResizeObserverEntry[], obs: ResizeObserver) => {
    allEntries = allEntries.concat(entries);
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const triggered = new Set<Element>();
        for (let i = 0; i < allEntries.length; i++) {
          if (triggered.has(allEntries[i].target)) continue;
          triggered.add(allEntries[i].target);
          const cbs = callbacks.get(allEntries[i].target);
          for (const cb of cbs ?? []) {
            cb(allEntries[i], obs);
          }
        }
        allEntries = [];
        ticking = false;
      });
    }
    ticking = true;
  });

  return {
    observer,
    subscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      observer.observe(target);
      const cbs = callbacks.get(target) ?? [];
      cbs.push(callback);
      callbacks.set(target, cbs);
    },
    unsubscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      const cbs = callbacks.get(target) ?? [];
      if (cbs.length === 1) {
        observer.unobserve(target);
        callbacks.delete(target);
        return;
      }
      const cbIndex = cbs.indexOf(callback);
      if (cbIndex !== -1) cbs.splice(cbIndex, 1);
      callbacks.set(target, cbs);
    },
  };
}

let RES_OBS: ReturnType<typeof createResizeObserver>;
const getResizeObserver = () => (!RES_OBS ? (RES_OBS = createResizeObserver()) : RES_OBS);

export type UseResizeObserverCallback = (
  entry: ResizeObserverEntry,
  observer: ResizeObserver
) => unknown;

export function useResizeObserver<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  callback: UseResizeObserverCallback
): ResizeObserver {
  const resizeObserver = getResizeObserver();
  const storedCallback = useLatest(callback);

  useLayoutEffect(() => {
    let didUnsubscribe = false;
    const targetEl = target && "current" in target ? target.current : target;
    if (!targetEl) return () => {};

    function cb(entry: ResizeObserverEntry, observer: ResizeObserver) {
      if (didUnsubscribe) return;
      storedCallback.current(entry, observer);
    }

    resizeObserver.subscribe(targetEl as HTMLElement, cb);

    return () => {
      didUnsubscribe = true;
      resizeObserver.unsubscribe(targetEl as HTMLElement, cb);
    };
  }, [target, resizeObserver, storedCallback]);

  return resizeObserver.observer;
}

const useScrollbarSize = () => {
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const scrollbarSize = useMemo(() => scrollbarWidth(), []);

  useLayoutEffect(() => {
    if (scrollContainer) {
      setIsScrollbarVisible(scrollContainer.scrollHeight > scrollContainer.clientHeight);
    }
  }, [scrollContainer]);

  useResizeObserver(scrollContainer, (e) =>
    setIsScrollbarVisible(e.target.scrollHeight > e.target.clientHeight)
  );

  return [isScrollbarVisible ? scrollbarSize : 0, setScrollContainer] as const;
};

export default useScrollbarSize;
