import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import Settings from "@mui/icons-material/Settings";
import { SvgIcon } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {
  ElementType,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler as ReactMouseEventHandler,
  useState,
} from "react";
import { useIntl } from "react-intl";
import LogoSvg from "./icons/logo.svg";

export type SettingsMenuProps<C extends ElementType = "li"> = {
  onClickLogout: ReactMouseEventHandler<HTMLLIElement>;
  settingsMessage: string;
  logoutMessage: string;
  userTitle: string;
  userName: string;
  className?: string;
  iconSrc?: string;
  icon?: React.ReactNode;
  settingsItemProps?: MenuItemProps<C, { component?: C; button?: true }>;
  displaySettings?: boolean;
  appVersion?: string;
};

const SettingsMenu = <C extends ElementType = "li">({
  className,
  iconSrc,
  icon,
  onClickLogout,
  settingsItemProps,
  settingsMessage,
  logoutMessage,
  userTitle,
  userName,
  displaySettings = true,
  appVersion,
}: SettingsMenuProps<C>) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { formatMessage } = useIntl();

  const handleClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSettingsClick = (event: ReactMouseEvent) => {
    if (settingsItemProps?.onClick) {
      settingsItemProps.onClick(event);
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        className={className}
        sx={{
          marginLeft: "auto",
          borderRadius: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onClick={handleClick}
        disableRipple
        size="large"
      >
        {iconSrc ? (
          <img src={iconSrc} alt="Logo" style={{ maxHeight: 42, width: 28 }} />
        ) : (
          <SvgIcon fontSize="large">{icon || <LogoSvg />}</SvgIcon>
        )}
        <ArrowDropDown sx={{ marginLeft: "10px" }} />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiMenu-list": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {userName ? (
          <div>
            <CardContent>
              <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                {userTitle}
              </Typography>
              <Typography variant="body2" component="div">
                {userName}
              </Typography>
            </CardContent>
            <Divider />
          </div>
        ) : null}
        {displaySettings && (
          <MenuItem dense {...settingsItemProps} onClick={onSettingsClick}>
            <ListItemIcon sx={{ minWidth: "40px" }}>
              <Settings color={"primary"} />
            </ListItemIcon>
            <ListItemText primary={settingsMessage} primaryTypographyProps={{ color: "primary" }} />
          </MenuItem>
        )}
        <MenuItem dense onClick={onClickLogout}>
          <ListItemIcon sx={{ minWidth: "40px" }}>
            <PowerSettingsNew color={"primary"} />
          </ListItemIcon>
          <ListItemText primary={logoutMessage} primaryTypographyProps={{ color: "primary" }} />
        </MenuItem>
        <Divider sx={{ "&.MuiDivider-root": { margin: 0.5 } }} />
        <CardContent
          sx={{ "&:last-child": { paddingTop: 0, paddingBottom: 0.5, textAlign: "center" } }}
        >
          <Typography variant="caption" sx={{ color: "grey.500" }}>
            {`${formatMessage({
              defaultMessage: "App version",
            })}: ${appVersion}`}
          </Typography>
        </CardContent>
      </Menu>
    </>
  );
};

export default SettingsMenu;
