import { MenuItemProps, SxProps } from "@mui/material";
import FormControl, { FormControlProps as MuiFormControlProps } from "@mui/material/FormControl";
import FormHelperText, {
  FormHelperTextProps as MuiFormHelperTextProps,
} from "@mui/material/FormHelperText";
import InputLabel, { InputLabelProps as MuiInputLabelProps } from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import React, { ReactNode, useMemo } from "react";

export interface InjectedOptionProps<T> {
  option: T;
  index: number;
}

const NONE_VALUE = "$none$" as const;

type ValueType<T> = T | typeof NONE_VALUE | "";

export interface SelectProps<
  V extends string,
  T extends { [k in V]: string | number | object } = { [k in V]: string | number | object },
> extends Omit<MuiSelectProps<ValueType<T[V]>>, "children" | "variant" | "onChange"> {
  label?: string;
  items?: T[];
  valueKey: V;
  helperText?: string;
  error?: boolean;
  renderOption?:
    | ((props: InjectedOptionProps<T>) => JSX.Element | JSX.Element[] | string)
    | React.ReactElement<InjectedOptionProps<T>>;
  FormControlProps?: Partial<Omit<MuiFormControlProps, "sx">>;
  InputLabelProps?: Partial<MuiInputLabelProps>;
  FormHelperTextProps?: Partial<MuiFormHelperTextProps>;
  getMenuItemProps?: (option: T | null, index: number) => MenuItemProps;
  outerSx?: SxProps;
  variant?: MuiSelectProps["variant"];
  noneOptionLabel?: ReactNode;
  onChange?: (value: T[V] | null | string) => void;
}

const Select = <
  V extends string,
  T extends { [k in V]: string | number } = { [k in V]: string | number },
>({
  items,
  valueKey = "value" as V,
  label,
  labelId,
  helperText,
  error,
  className,
  required,
  FormControlProps,
  InputLabelProps,
  FormHelperTextProps,
  renderOption: _renderOption,
  fullWidth,
  getMenuItemProps,
  outerSx,
  variant = "filled",
  noneOptionLabel,
  value,
  onChange,
  ...rest
}: SelectProps<V, T>) => {
  const renderOption = useMemo(
    () =>
      typeof _renderOption === "function"
        ? (option: T, index: number) => _renderOption({ option, index })
        : _renderOption
          ? (option: T, index: number) => React.cloneElement(_renderOption, { option, index })
          : (option: T, _: number, vK: V) => option[vK],
    [_renderOption]
  );

  const actualValue =
    value === "" || value === NONE_VALUE || value === null || value === undefined
      ? noneOptionLabel
        ? NONE_VALUE
        : ""
      : value;

  return (
    <FormControl
      variant={variant}
      className={className}
      fullWidth={fullWidth}
      required={required}
      error={error}
      sx={outerSx}
      {...FormControlProps}
    >
      {label && (
        <InputLabel id={labelId} shrink error={error} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <MuiSelect<ValueType<T[V]>>
        labelId={labelId}
        error={error}
        fullWidth={fullWidth}
        variant={variant}
        value={actualValue}
        onChange={({ target: { value: newValue } }) => {
          onChange?.(newValue === NONE_VALUE || newValue === "" ? null : newValue);
        }}
        {...rest}
      >
        {noneOptionLabel && (
          <MenuItem value={NONE_VALUE} {...getMenuItemProps?.(null, 0)}>
            {noneOptionLabel}
          </MenuItem>
        )}
        {items?.map((option, index) => (
          <MenuItem
            value={option[valueKey]}
            key={option[valueKey]}
            {...getMenuItemProps?.(option, index + (noneOptionLabel ? 1 : 0))}
          >
            {renderOption(option, index + (noneOptionLabel ? 1 : 0), valueKey)}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
