import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Checkbox,
  styled,
  SxProps,
  Table,
  TableBody,
  TableRowProps,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import React, {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import {
  CellProps,
  Column,
  ColumnInstance,
  FilterTypes,
  IdType,
  Row,
  TableOptions,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListItemKeySelector } from "react-window";
import EmptyPlaceholder, { PlaceholderIcon } from "../EmptyPlaceholder";
import SubPage from "../SubPage";
import CollapsableFilterWrapper, {
  FilterViewsRenderProps,
} from "./filters/CollapsableFilterWrapper";
import GlobalFilter, { GlobalFilterProps } from "./filters/GlobalFilter";
import TableFooter from "./footer";
import TableHeader from "./header";
import TableRow, { TableRowContext } from "./row";
import TableSkeleton from "./skeleton";
import useScrollbarSize, { useResizeObserver } from "./useScrollbarSize";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export type VirtualListType<Type extends object> = FixedSizeList<TableRowContext<Type>>;
export type ToggleRowSelectType = {
  toggleRowSelected: (rowId: IdType<number | string>, set?: boolean) => void;
  toggleAllRowsSelected: (value?: boolean) => void;
};

const StyledFixedSizeList = styled(FixedSizeList)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  overflowX: "hidden",
})) as unknown as typeof FixedSizeList; // workaround to not loose the generic type parameter

export interface EnhancedTableProps<Type extends object, ErrorIconProps extends object> {
  columns: Column<Type>[];
  data: Type[];
  rowHeight?: number;
  getRowProps?: (row: Row<Type>) => Omit<Partial<TableRowProps>, "key">;
  onRowClick?: (row: Row<Type>) => void;
  queryStatus?: "error" | "loading" | "idle" | "success";
  emptyLineCount?: number; // number of empty lines at the bottom for overscrolling
  overscanCount?: number; // number lines to render outside the visible area
  hideFooter?: boolean; // hide the table footer
  hideHeader?: boolean; // hide the table header (with the column names)
  hideFilters?: boolean; // hide the filter header
  emptyIcon?: PlaceholderIcon;
  emptyMessage?: string;
  errorIcon?: PlaceholderIcon<ErrorIconProps>;
  errorIconProps?: Partial<ErrorIconProps>;
  errorMessage?: string;
  showHeader?: boolean; // show the widget header (with the global filter, prefix and postfix )
  disableGlobalFilter?: boolean; // hide the global filter in the widget header
  disableFilters?: boolean;
  globalFilterPlacement?: GlobalFilterProps["placement"];
  prefix?: ReactNode; // header prefix, before the global filter
  center?: ReactNode; // header center, after prefix before postfix in the middle
  postfix?: ReactNode; // header postfix, after the global filter
  sx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  action?: (
    row: Row<Type>,
    url: string,
    props: Omit<CellProps<Type>, "row">
  ) => ReactElement | null;
  actionCellWidth?: number;
  actionPosition?: "left" | "right";
  rowselect?: boolean;
  rowselectWidth?: number | string;
  rowselectminWidth?: number;
  rowselectChangeRef?: MutableRefObject<ToggleRowSelectType | undefined>;
  onRowSelectChange?: (ids: Record<string, boolean>) => void;
  filters?: (options: FilterViewsRenderProps) => ReactNode;
  filterTypes?: FilterTypes<Type>;
  defaultColumn?: Partial<Column<Type>>;
  reactTableOptions?: Partial<TableOptions<Type>>;
  renderView?: (data: Type[]) => ReactNode;
  limit?: number;
  showResultCount?: boolean;
  hiddenColumns?: string[];
  itemKey?: ListItemKeySelector<TableRowContext<Type>>;
  RowWrapper?: TableRowContext<Type>["RowWrapper"];
  virtualListRef?: React.LegacyRef<VirtualListType<Type>>;
  renderMessage?: (data: Type[]) => ReactNode;
  rowsPerPageOptions?: number[];
  pageFooter?: ReactNode;
  sidebar?: ReactNode;
  enableSorting?: boolean;
}

const LocalFilterViews = <Type extends object>({
  setClearable,
  onClearRef,
  hasLocalFilters,
  allColumns,
  clearLocalFilters,
}: FilterViewsRenderProps & {
  hasLocalFilters: boolean;
  allColumns: ColumnInstance<Type>[];
  clearLocalFilters: () => void;
}) => {
  useEffect(() => {
    onClearRef.current = clearLocalFilters;
  }, [onClearRef, clearLocalFilters]);

  useEffect(() => {
    setClearable(hasLocalFilters);
  }, [setClearable, hasLocalFilters]);

  return (
    <>
      {allColumns.reduce((filterWidgets, column) => {
        if (column.canFilter) {
          filterWidgets.push(column.render("Filter"));
        }
        return filterWidgets;
      }, [] as ReactNode[])}
    </>
  );
};

function ETable<Type extends object = {}, ErrorIconProps extends object = {}>(
  {
    columns,
    data,
    onRowClick,
    getRowProps: getCustomRowProps,
    queryStatus = "success",
    disableGlobalFilter,
    globalFilterPlacement,
    emptyLineCount = 0,
    rowHeight = 28,
    hideHeader,
    hideFooter = true,
    emptyIcon,
    emptyMessage,
    errorIcon = WarningIcon,
    errorIconProps,
    errorMessage,
    overscanCount,
    prefix,
    center,
    postfix,
    showHeader,
    sx = [],
    innerSx = [],
    headerSx,
    action,
    actionCellWidth = 150,
    actionPosition = "right",
    rowselect,
    rowselectWidth,
    rowselectminWidth,
    rowselectChangeRef,
    onRowSelectChange,
    filters,
    filterTypes,
    defaultColumn,
    disableFilters = true,
    reactTableOptions,
    renderView,
    limit = 100,
    showResultCount = false,
    hiddenColumns,
    hideFilters = false,
    itemKey,
    RowWrapper,
    virtualListRef,
    renderMessage,
    pageFooter,
    sidebar,
    enableSorting,
  }: EnhancedTableProps<Type, ErrorIconProps>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { url } = useRouteMatch();
  const { formatMessage } = useIntl();

  const [scrollbarSize, listOuterDomRef] = useScrollbarSize();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter, filters: localFilters, selectedRowIds: selectedRowIds_ }, // eslint-disable-line @typescript-eslint/naming-convention
    visibleColumns,
    setAllFilters,
    allColumns,
    setHiddenColumns,
    toggleRowSelected,
    toggleAllRowsSelected,
    // selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      disableGlobalFilter,
      disableFilters: !!filters && disableFilters,
      filterTypes,
      defaultColumn,
      autoResetSelectedRows: false,
      // autoResetFilters: false,
      // manualFilters: true,
      ...reactTableOptions,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    useExpanded,
    useRowSelect,
    (hooks) => {
      if (action) {
        hooks.visibleColumns.push((vcs) => [
          ...(actionPosition === "right" ? vcs : []),
          {
            id: "action",
            Cell: ({ row, ...props }: CellProps<Type>) => action(row, url, props),
            width: actionCellWidth,
            minWidth: actionCellWidth,
          },
          ...(actionPosition === "left" ? vcs : []),
        ]);
      }
    },
    useCallback(
      (hooks) => {
        if (rowselect) {
          hooks.visibleColumns.push((vcs) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }: any) => {
                const { title, ...toggleAllRowsSelectProps } = getToggleAllRowsSelectedProps();
                return (
                  <div>
                    <Checkbox sx={{ padding: 0 }} size="small" {...toggleAllRowsSelectProps} />
                  </div>
                );
              },
              Cell: ({ row }: any) => {
                const { title, ...toggleRowSelectProps } = row.getToggleRowSelectedProps();
                return (
                  <div>
                    <Checkbox sx={{ padding: 0 }} size="small" {...toggleRowSelectProps} />
                  </div>
                );
              },
              width: rowselectWidth ?? 10,
              minWidth: rowselectminWidth ?? 10,
            },
            ...vcs,
          ]);
        }
      },
      [rowselect, rowselectWidth, rowselectminWidth]
    )
  );

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns);
    }
  }, [setHiddenColumns, hiddenColumns]);

  const filteredData = useMemo(() => rows.map((r) => r.original), [rows]);
  const overLimit =
    limit && data !== undefined && queryStatus === "success" && data.length === limit;

  const resultMessage = (
    <>
      {queryStatus === "success" && data?.length !== filteredData?.length && overLimit && (
        <Typography color="primary.contrastText" sx={{ ml: 1 }}>
          {formatMessage(
            {
              defaultMessage:
                "{dataCount} {dataCount, plural, =0 {result} =1 {result} other {results}} from",
            },
            { dataCount: filteredData.length }
          )}
          <Box component="span" sx={{ whiteSpace: "pre" }}>
            {" "}
          </Box>
        </Typography>
      )}
      <Typography color="primary.contrastText">
        {overLimit && formatMessage({ defaultMessage: "first" })}{" "}
        {queryStatus === "success" &&
          `${formatMessage(
            {
              defaultMessage:
                "{dataCount} {dataCount, plural, =0 {result} =1 {result} other {results}}",
            },
            {
              dataCount: overLimit ? data?.length : filteredData?.length,
              hun: data?.length !== filteredData?.length && overLimit && "ból",
            }
          )}`}
      </Typography>
      {overLimit && (
        <HtmlTooltip
          title={
            <span>
              {formatMessage(
                {
                  defaultMessage:
                    "Limited result (limit {limit}). To display more results, narrow down the advanced search settings",
                },
                { limit }
              )}
            </span>
          }
          sx={{ color: "common.white" }}
        >
          <InfoIcon fontSize="small" sx={{ color: "common.white" }} />
        </HtmlTooltip>
      )}
    </>
  );

  const hasAdvancedFilters = !!filters || !disableFilters;

  const tableRowContext = useMemo<TableRowContext<Type>>(
    () => ({
      rows,
      prepareRow,
      onRowClick,
      getCustomRowProps,
      RowWrapper,
      selectedRowIds_,
    }),
    [rows, prepareRow, onRowClick, getCustomRowProps, RowWrapper, selectedRowIds_]
  );

  useEffect(() => {
    onRowSelectChange?.(selectedRowIds_);
  }, [selectedRowIds_, onRowSelectChange]);

  useEffect(() => {
    if (rowselectChangeRef) {
      rowselectChangeRef.current = {
        toggleAllRowsSelected,
        toggleRowSelected,
      };
    }
  }, [toggleAllRowsSelected, toggleRowSelected, rowselectChangeRef]);

  const target = useRef<HTMLDivElement>(null);
  const [myWidth, setMyWidth] = useState<number>(0);
  const { current } = target;

  useLayoutEffect(() => {
    if (!current) {
      return;
    }
    const { scrollWidth } = current;
    setMyWidth(scrollWidth);
  }, [current]);

  useResizeObserver(target, (entry) => {
    const { scrollWidth } = entry.target;
    setMyWidth(scrollWidth);
  });

  const defaultView = (
    d: Type[] // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => (
    <Box
      sx={{
        height: "100%",
        width: "inherit",
        overflowX: "auto",
        overflowY: "hidden",
        display: "flex",
        flex: 1,
      }}
    >
      <Table
        {...getTableProps()}
        component="div"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          height: "100%",
        }}
      >
        {/* RESIZER tableheader: used for calculating the dynamic width of the table */}
        <TableHeader
          ref={target}
          headerGroups={headerGroups}
          sx={{
            height: "0px",
            visibility: "hidden",
            paddingRight: `${scrollbarSize}px`,
            "@media (min-width: 600px)": {
              minHeight: "unset",
              height: "0px",
            },
            "@media (min-width: 0px) and (orientation: landscape)": {
              minHeight: "unset",
              height: "0px",
            },
          }}
        />
        {!hideHeader && (
          <TableHeader
            headerGroups={headerGroups}
            enableSorting={enableSorting}
            sx={{
              paddingRight: `${scrollbarSize}px`,
              minWidth: myWidth,
            }}
          />
        )}
        <TableBody
          {...getTableBodyProps()}
          component="div"
          sx={{
            flex: 1,
            position: "relative",
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <AutoSizer
            style={{
              flexGrow: 1,
              flexBasis: myWidth,
              minWidth: myWidth,
            }}
          >
            {({ height }) =>
              queryStatus === "loading" || queryStatus === "idle" ? (
                <TableSkeleton
                  width={"100%"}
                  height={height}
                  rowHeight={rowHeight}
                  columns={visibleColumns}
                />
              ) : (
                <StyledFixedSizeList<TableRowContext<Type>>
                  width={"100%"}
                  height={height}
                  itemCount={rows.length + emptyLineCount}
                  itemSize={rowHeight}
                  itemData={tableRowContext}
                  overscanCount={overscanCount}
                  outerRef={listOuterDomRef}
                  itemKey={itemKey}
                  ref={virtualListRef}
                  style={{
                    overflowX: "hidden",
                  }}
                >
                  {TableRow}
                </StyledFixedSizeList>
              )
            }
          </AutoSizer>
        </TableBody>
        {!hideFooter && <TableFooter footerGroups={footerGroups} />}
      </Table>
    </Box>
  );

  return (
    <SubPage
      ref={ref}
      showHeader={showHeader}
      prefix={
        <>
          <Box mr={1}>{prefix}</Box>
          {!disableGlobalFilter && (
            <GlobalFilter
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              placement={globalFilterPlacement}
            />
          )}
          {showResultCount && resultMessage}
          {renderMessage?.(filteredData)}
        </>
      }
      center={center}
      postfix={postfix}
      sx={[
        {
          overflow: "hidden",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      innerSx={[
        {
          display: "flex",
          flexDirection: "column",
        },
        ...(Array.isArray(innerSx) ? innerSx : [innerSx]),
      ]}
      headerSx={[
        {
          justifyContent: "flex-start",
        },
        ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
      ]}
    >
      {hasAdvancedFilters ? (
        <CollapsableFilterWrapper style={{ ...(hideFilters && { display: "none" }) }}>
          {filters ||
            ((options) => (
              <LocalFilterViews
                {...options}
                hasLocalFilters={localFilters.length > 0}
                clearLocalFilters={() => setAllFilters([])}
                allColumns={allColumns}
              />
            ))}
        </CollapsableFilterWrapper>
      ) : null}
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flex: 1,
          flexShrink: 1,
          overflow: "hidden",
        }}
      >
        {sidebar}
        {queryStatus === "error" ? (
          <EmptyPlaceholder
            Icon={errorIcon}
            iconProps={errorIconProps}
            message={
              errorMessage ??
              formatMessage({
                id: "query error message",
                defaultMessage: "Query Error",
                description: "Table Component query error message",
              })
            }
            sx={{ flex: 1 }}
          />
        ) : queryStatus === "success" && rows.length === 0 ? (
          <EmptyPlaceholder
            Icon={emptyIcon}
            message={
              emptyMessage ??
              formatMessage({
                id: "empty table message",
                defaultMessage: "No data match your query",
                description: "Table Component no result query message",
              })
            }
            sx={{ flex: 1 }}
          />
        ) : (
          renderView?.(filteredData) || defaultView(filteredData)
        )}
      </Box>
      {pageFooter}
    </SubPage>
  );
}

const EnhancedTable = forwardRef(ETable) as <
  Type extends object = {},
  ErrorIconProps extends object = {},
>(
  props: EnhancedTableProps<Type, ErrorIconProps> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof ETable>;

export default EnhancedTable;
