import { useMemo } from "react";
import { useQuery } from "react-query";
import { useApi } from "../query";
import { BaseType, ID } from "../types";
import { ApiResponseMany, JsonapiError } from "./types";
import { GetOneParams, useGetOneSearchParams } from "./useGetOne";
import { transformJsonapi } from "./utils";

function* flattenFilters(
  filters: Filters,
  prefix = ""
): IterableIterator<[key: string, value: string | number | Date | string[]]> {
  if (filters.or) {
    yield* flattenFilters(filters.or, "[or]");
  }

  for (const [field, filter] of Object.entries(filters)) {
    if (field === "or") {
      continue;
    }
    for (const [type, value] of Object.entries(filter)) {
      if (value !== undefined) {
        yield [`${prefix}[${field}][${type}]`, value];
      }
    }
  }
}

type FiltersExpr = {
  [filter in "gt" | "gte" | "lt" | "lte" | "eq" | "ne" | "pattern" | "bucket" | "similar"]?:
    | string
    | number
    | Date;
} & {
  is?: "true" | "false" | "not_true" | "not_false" | "null";
  null?: "true" | "false";
} & {
  [filter in "in" | "nin" | "rfid" | "m2m-integer"]?: string | (string | "null")[];
} & {
  [filter in "contains" | "contained-by" | "overlaps" | "m2m"]?: string | string[];
};

type FieldFilters = Record<string, FiltersExpr>;

export type Filters = Record<string, FiltersExpr | FieldFilters> & {
  or?: FieldFilters;
};

export interface PageLimit {
  limit?: number;
  count?: "on" | "off";
}

export interface PageCursorBefore {
  before: ID;
  after?: never;
  offset?: never;
}

export interface PageCursorAfter {
  after: ID;
  before?: never;
  offset?: never;
}

export interface PageCursorOffset {
  offset: number;
  before?: never;
  after?: never;
}

export interface GetAllParams<T, S> extends GetOneParams<T, S> {
  filter?: Filters;
  page?: (PageCursorBefore | PageCursorAfter | PageCursorOffset | {}) & PageLimit;
  sort?: readonly string[];
}

export function useGetAllSearchParams<T, S>(
  baseSearchParams: string,
  { filter, page, sort }: Pick<GetAllParams<T, S>, "filter" | "page" | "sort">
) {
  return useMemo(() => {
    if (!filter && !page && !sort) {
      return baseSearchParams;
    }

    const p = new URLSearchParams(baseSearchParams);

    if (filter) {
      for (const [key, value] of flattenFilters(filter)) {
        if (Array.isArray(value)) {
          p.set(`filter${key}`, value.join(","));
        } else if (value instanceof Date) {
          p.set(`filter${key}`, value.toISOString());
        } else if (typeof value === "number") {
          p.set(`filter${key}`, String(value));
        } else {
          p.set(`filter${key}`, value);
        }
      }
    }

    if (page) {
      for (const [key, value] of Object.entries(page)) {
        if (value !== undefined) {
          p.set(`page[${key}]`, value.toString());
        }
      }
    }

    if (sort) {
      p.set("sort", sort.join(","));
    }

    const str = p.toString();
    return str && `?${str}`;
  }, [filter, page, sort, baseSearchParams]);
}

export default function useGetAll<T extends BaseType, S = T[] & { $count?: number }>(
  type: T["type"],
  {
    include,
    fields,
    filter,
    page,
    sort,
    ...reactQueryOptions
  }: GetAllParams<T[] & { $count?: number }, S> = {}
) {
  const { includePaths, searchParams: getOneSearchParams } = useGetOneSearchParams<T[], S>({
    include,
    fields,
  });

  const searchParams = useGetAllSearchParams(getOneSearchParams, {
    filter,
    page,
    sort,
  });

  const api = useApi();

  const fetchAndTransform = async () =>
    transformJsonapi<ApiResponseMany, T>(
      await api<ApiResponseMany>(`/${type}${searchParams}`),
      includePaths
    );

  return useQuery<T[] & { $count?: number }, JsonapiError, S>(
    [type, searchParams],
    () => fetchAndTransform(),
    reactQueryOptions
  );
}
