import "@fontsource/roboto/300.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/index.css";
import type {} from "@mui/lab/themeAugmentation";
import {
  dialogContentClasses,
  tableSortLabelClasses,
  Theme,
  typographyClasses,
} from "@mui/material";
import { createTheme, lighten } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    header: Palette["primary"];

    highlight: {
      dirty: string;
    };
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    header?: PaletteOptions["primary"];

    highlight?: {
      dirty?: string;
    };
  }
}

export const header = {
  locationToolbarHeight: 32,
};

export const createAppTheme = (theme: Theme) =>
  createTheme(theme, {
    palette: {
      highlight: {
        dirty: lighten(theme.palette.warning.light, 0.6),
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontSize: "2.5rem",
      },
      button: {
        fontWeight: 700,
        letterSpacing: "0.02em",
        textTransform: "none",
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: theme.palette.primary.main,
          },
          arrow: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: theme.palette.text.secondary,
          },
          secondary: {
            color: theme.palette.text.primary,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          sizeSmall: {
            borderRadius: 16,
            fontSize: 14,
          },
          root: {
            borderRadius: 10,
            fontSize: 14,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          underline: {
            "&:before, &:hover:before": {
              borderBottom: `2px solid ${theme.palette.divider}`,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&:before, &:hover:not(.Mui-disabled):before": {
              borderBottom: `2px solid ${theme.palette.divider}`,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: 15,
          },
          fullWidth: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            backgroundColor: theme.palette.background.default,
          },
          input: {
            paddingTop: 12.5,
            paddingBottom: 12.5,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: 8,
          },
          track: {
            opacity: 0.2,
            borderRadius: 4,
          },
          thumb: {
            borderRadius: "20%",
          },
          sizeSmall: {
            padding: 3,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 12,
            backgroundColor: "initial",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
          spacing: {
            "& > :not(:first-of-type)": {
              marginLeft: 32,
            },
            "& > :last-of-type": {
              marginRight: 16,
            },
            "& > :first-of-type": {
              marginLeft: 16,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            paddingTop: 8,
            paddingBottom: 8,
            textAlign: "center",
            [`&.${typographyClasses.root} + .${dialogContentClasses.root}, div`]: {
              paddingTop: "20px",
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          li: {
            fontFamily: "Roboto",
            letterSpacing: 0.08,
            fontWeight: 400,
            fontSize: 16,
            marginTop: 8,
            color: theme.palette.background.default,
          },
          separator: {
            marginTop: 8,
            color: theme.palette.background.default,
            marginLeft: 16,
            marginRight: 16,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "inherit",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 72,
            [theme.breakpoints.up("sm")]: {
              minWidth: 72,
            },
          },
          fullWidth: {
            minWidth: 72,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 4,
            borderRadius: 2,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.Mbp-dirty": {
              fontWeight: 700,
              color: theme.palette.primary.main,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: theme.spacing(1),
            minWidth: 160,
          },
          fullWidth: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "inherit",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&:focus": {
              color: theme.palette.primary.contrastText,
            },
            "&:hover": {
              color: theme.palette.primary.contrastText,
            },
            "&.Mui-active": {
              color: theme.palette.primary.contrastText,
              "&&.Mui-icon": {
                color: theme.palette.primary.contrastText,
              },
            },
            [`&.${tableSortLabelClasses["active"]}`]: {
              color: theme.palette.primary.contrastText,
              [`&&.${tableSortLabelClasses["icon"]}`]: {
                color: theme.palette.primary.contrastText,
              },
            },
            color: theme.palette.primary.contrastText,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            padding: 0,
          },
          button: {
            fontSize: "100%",
            fontFamily: "inherit",
            border: "0",
            padding: "0",
            margin: "0",
            cursor: "pointer",
            color: "inherit",
            backgroundColor: "unset",
            "& + button": {
              margin: 0,
            },
            "&:focus": {
              outline: "none",
            },
          },
          ".Mbp-dirty>.MuiInputLabel-root": {
            fontWeight: 700,
            color: theme.palette.primary.main,
          },
          ".MuiFormControlLabel-root.Mbp-dirty .MuiFormControlLabel-label": {
            fontWeight: 700,
            color: theme.palette.primary.main,
          },
        },
      },
    },
  });

const baseTheme = createTheme({
  palette: {
    common: {
      white: "#FEFEFE",
    },
    primary: {
      main: "#2587BC",
      contrastText: "#FEFEFE",
    },
    secondary: {
      main: "#2587BC",
    },
    warning: {
      main: "#EF9F16",
      contrastText: "#FEFEFE",
    },
    divider: "#D4D4D4",
    text: {
      primary: "#515151",
      secondary: "#2587bc",
    },
    background: {
      paper: "#FEFEFE",
      default: "#F9F9F9",
    },
    header: {
      main: "#2587BC",
      dark: "#143675",
      light: "#4b5fa4",
    },
    info: {
      main: "#25BC5A",
      contrastText: "#FEFEFE",
    },
  },
});

export const muiTheme = createAppTheme(baseTheme);

export default muiTheme;
