import { Box, Paper, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { ForwardedRef, ReactNode, forwardRef } from "react";

interface SubPageProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  showHeader?: boolean;
  prefix?: ReactNode;
  center?: ReactNode;
  postfix?: ReactNode;
  elevation?: number;
}

const SubPage = forwardRef(
  (
    {
      sx = [],
      innerSx = [],
      headerSx = [],
      children,
      prefix,
      center,
      postfix,
      showHeader = true,
      elevation,
    }: SubPageProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Paper
      elevation={elevation}
      ref={ref}
      sx={[
        {
          padding: 0,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {showHeader && (prefix || postfix) && (
        <Box
          sx={[
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "primary.main",
              minHeight: "50px",
              padding: (theme) => theme.spacing(0, 3),
              borderRadius: (theme) => theme.spacing(0.5, 0.5, 0, 0),
            },
            ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
          ]}
        >
          {prefix}
          <Box sx={{ flex: 1 }} />
          <Box sx={{ position: "absolute" }}>{center}</Box>
          {postfix}
        </Box>
      )}
      <Box
        sx={[
          {
            flex: 1,
            overflow: "hidden",
            borderRadius: (theme) => theme.spacing(0, 0, 0.5, 0.5),
          },
          ...(Array.isArray(innerSx) ? innerSx : [innerSx]),
        ]}
      >
        {children}
      </Box>
    </Paper>
  )
);

export default SubPage;
