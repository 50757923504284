import { Reducer, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useApi } from "../query/ApiProvider";
import streamQuery from "../query/streamQuery";
import { BaseType } from "../types";
import { ApiResponseMany, JsonapiError } from "./types";
import { GetAllParams, useGetAllSearchParams } from "./useGetAll";
import { useGetOneSearchParams } from "./useGetOne";
import { transformJsonapi } from "./utils";

export interface GetStreamParams<T>
  extends Pick<GetAllParams<T, T>, "include" | "fields" | "filter" | "enabled" | "onSuccess"> {
  refetchInterval?: number;
  resetInterval?: number;
  page: {
    limit: number;
  };
}

interface UseGetStreamState<T extends BaseType> {
  status: "idle" | "loading" | "success" | "error";
  isFetching: boolean;
  error?: JsonapiError;
  data?: T[];
  dataUpdatedAt?: number;
  failureCount: number;
}

type UseGetStreamAction<T extends BaseType> =
  | { type: "reset" | "loading" }
  | { type: "data"; payload: T[] }
  | { type: "error"; payload: JsonapiError };

function reducer<T extends BaseType>(
  prev: UseGetStreamState<T>,
  action: UseGetStreamAction<T>
): UseGetStreamState<T> {
  switch (action.type) {
    case "reset":
      if (!prev.data && !prev.error && !prev.isFetching) {
        return prev;
      }
      return {
        ...prev,
        data: undefined,
        error: undefined,
        isFetching: false,
      };

    case "loading":
      if (prev.status !== "idle" && prev.isFetching) {
        return prev;
      }
      return {
        ...prev,
        status: prev.status === "idle" ? "loading" : prev.status,
        isFetching: true,
      };

    case "data":
      return {
        ...prev,
        status: "success",
        data: action.payload,
        dataUpdatedAt: Date.now(),
        error: undefined,
        isFetching: false,
        failureCount: 0,
      };

    case "error":
      return {
        ...prev,
        data: undefined,
        status: "error",
        error: action.payload,
        isFetching: false,
        failureCount: prev.failureCount + 1,
      };
  }
}

export default function useGetStream<T extends BaseType>(
  type: T["type"],
  {
    include,
    fields,
    filter,
    page: { limit },
    enabled = true,
    refetchInterval,
    resetInterval,
    onSuccess,
  }: GetStreamParams<T[]>,
  cursor: keyof T & string = "id"
) {
  const [state, dispatch] = useReducer<Reducer<UseGetStreamState<T>, UseGetStreamAction<T>>>(
    reducer,
    {
      status: "idle",
      isFetching: !!enabled,
      failureCount: 0,
    }
  );

  const [resetCounter, setReset] = useState(0);
  const reset = useCallback(() => setReset((r) => r + 1), []);

  const triggerRef = useRef(() => {});
  const refetch = useCallback(() => triggerRef.current(), []);

  const onSuccessRef = useRef(onSuccess);
  useEffect(() => {
    onSuccessRef.current = onSuccess;
  }, [onSuccess]);

  const { includePaths, searchParams: getOneSearchParams } = useGetOneSearchParams<T[], T[]>({
    include,
    fields,
  });

  const getAllSearchParams = useGetAllSearchParams(getOneSearchParams, {
    filter,
    page: { limit },
    sort: [`-${cursor}`],
  });

  const api = useApi();

  // The value of this "callback" will change exactly when the query should be reset
  const fetchWithCursor = useCallback(
    async (value?: unknown) => {
      let response: Promise<ApiResponseMany>;
      if (!value) {
        response = api<ApiResponseMany>(`/${type}${getAllSearchParams}`);
      } else {
        const p = new URLSearchParams(getAllSearchParams);
        p.set(`filter[${cursor}][gt]`, String(value));
        response = api<ApiResponseMany>(`/${type}?${p.toString()}`);
      }
      return transformJsonapi(await response, includePaths);
    },
    [api, type, cursor, getAllSearchParams, includePaths]
  );

  useEffect(() => {
    dispatch({ type: "reset" });

    if (enabled) {
      const [cancel, trigger] = streamQuery<T, JsonapiError>(fetchWithCursor, cursor, limit, {
        onLoading: () => dispatch({ type: "loading" }),
        onData: (payload) => dispatch({ type: "data", payload }),
        onNewData: (data) => onSuccessRef.current?.(data),
        onError: (payload) => dispatch({ type: "error", payload }),
      });
      triggerRef.current = trigger;
      return cancel;
    }
  }, [fetchWithCursor, enabled, cursor, limit, resetCounter]);

  useEffect(() => {
    if (enabled && refetchInterval) {
      const handle = setInterval(() => triggerRef.current(), refetchInterval);
      return () => clearInterval(handle);
    }
  }, [enabled, refetchInterval]);

  useEffect(() => {
    if (enabled && resetInterval) {
      const handle = setInterval(() => setReset((r) => r + 1), resetInterval);
      return () => clearInterval(handle);
    }
  }, [enabled, resetInterval]);

  return {
    ...state,
    isLoading: state.status === "loading",
    isSuccess: state.status === "success",
    isError: state.status === "error",
    isFetching: state.isFetching,
    failureCount: state.failureCount,
    refetch,
    reset,
  };
}
