import { styled, TableCell, TableRow, Typography, TypographyProps } from "@mui/material";
import { memo } from "react";
import { Row, TableInstance, TableRowProps } from "react-table";
import { areEqual, ListChildComponentProps } from "react-window";
import getCustomColumnProps from "./getCustomColumnProps";

const SpanTypography = (props: TypographyProps<"div">) => (
  <Typography {...props} component={"div"} />
);

export const BodyCellContentWrapper = styled(SpanTypography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1.5, 0, 2),
}));

const TableBodyCell = styled(TableCell)({
  padding: 0,
  color: "inherit",
  borderBottom: 0,
});

const TableBodyRow = styled(TableRow)(({ theme }) => ({
  padding: 0,
  cursor: "default",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  outline: `1px solid ${theme.palette.divider}`,
})) as typeof TableRow;

export interface TableRowContext<Type extends object> {
  rows: Row<Type>[];
  prepareRow: TableInstance<Type>["prepareRow"];
  getCustomRowProps?: (row: Row<Type>) => Omit<Partial<TableRowProps>, "key">;
  onRowClick?: (row: Row<Type>) => void;
  RowWrapper?: React.ComponentType<{
    index: number;
    row: Type;
    children?: (userProps?: object) => React.ReactNode;
  }>;
}

const RenderTableBodyRow = <Type extends object>({
  index,
  style,
  data: { rows, prepareRow, getCustomRowProps, onRowClick, RowWrapper },
}: ListChildComponentProps<TableRowContext<Type>>) => {
  if (index > rows.length - 1) {
    return <TableRow id="extraEmptyRow" component="div" />;
  }

  const row = rows[index];
  prepareRow(row);

  const cells = (userProps?: object) =>
    row.cells.map((cell) => (
      //eslint-disable-next-line react/jsx-key
      <TableBodyCell
        {...cell.getCellProps(getCustomColumnProps(cell.column))} // includes a `key` prop
        component="div"
      >
        {cell.column.notWrapped ? (
          cell.render("Cell", userProps)
        ) : (
          <BodyCellContentWrapper noWrap overflow={cell.column.id === "fix" ? "visible" : "hidden"}>
            {cell.render("Cell", userProps)}
          </BodyCellContentWrapper>
        )}
      </TableBodyCell>
    ));

  return (
    <TableBodyRow
      {...row.getRowProps([{ style }, getCustomRowProps?.(row) ?? {}])}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
      component="div"
    >
      {RowWrapper ? (
        <RowWrapper index={index} row={row.original}>
          {(userProps) => cells(userProps)}
        </RowWrapper>
      ) : (
        cells()
      )}
    </TableBodyRow>
  );
};

export default memo(RenderTableBodyRow, areEqual) as typeof RenderTableBodyRow;
