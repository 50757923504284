import { useEffect, useRef } from "react";

export default function useOnChange<T>(
  currentValue: T,
  onChange: (current: T, prev: T | undefined) => void,
  skipFirst: boolean = false
) {
  const isFirst = useRef(true);
  const ref = useRef<T | undefined>();
  useEffect(() => {
    if (ref.current !== currentValue) {
      if (!skipFirst || !isFirst.current) {
        onChange(currentValue, ref.current);
      }
      isFirst.current = false;
      ref.current = currentValue;
    }
  }, [currentValue, onChange, skipFirst]);
}
