import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useReducer } from "react";
import useDebounce from "../utils/useDebounce";
import useURLSearchParams from "../utils/useURLSearchParams";
import { FilterViewsRenderProps } from "./filters/CollapsableFilterWrapper";
import { Filter, FilterView } from "./filters/base";

interface FilterHeaderProps {
  tableFilters: FilterView[];
  onFilterChange?: (filters: Filter[]) => void;
}

type FilterAction = /*{ type: "clear" } | */ { type: "set"; payload: Filter };

function reducer(filters: Filter[], action: FilterAction) {
  switch (action.type) {
    // case "clear":
    //   return filters.length === 0 ? filters : [];
    case "set":
      const { payload } = action;
      const index = filters.findIndex((filter) => filter.id === payload.id);

      const noValue =
        isEmpty(payload.value) &&
        typeof payload.value !== "boolean" &&
        typeof payload.value !== "number";

      if (index === -1 ? noValue : isEqual(payload, filters[index])) {
        return filters;
      } else if (index === -1) {
        // add
        return filters.concat(payload);
      } else if (noValue) {
        // remove
        return filters.slice(0, index).concat(filters.slice(index + 1));
      } else {
        // update
        return filters.slice(0, index).concat(payload, filters.slice(index + 1));
      }
  }
}

const RemoteFilterViews = ({
  tableFilters,
  onFilterChange,
  setClearable,
  onClearRef,
  getFilterProps,
}: FilterHeaderProps & FilterViewsRenderProps & { getFilterProps?: (id: string) => any }) => {
  const [urlSearchParams, setUrlSearchParams] = useURLSearchParams();
  const [filters, dispatch] = useReducer(reducer, [] as Filter[]);

  const debouncedFilters = useDebounce(filters, 450);

  useEffect(() => {
    onFilterChange?.(debouncedFilters);
  }, [debouncedFilters, onFilterChange]);

  const clearable = filters.length > 0;

  useEffect(() => {
    setClearable(clearable);
  }, [setClearable, clearable]);

  onClearRef.current = () => {
    setUrlSearchParams(
      tableFilters.reduce(
        (reset, { id }) => ({ ...(reset ?? {}), [id]: null }),
        {} as Record<string, null>
      )
    );
  };

  const setFilterState = useCallback(
    (
      id: string,
      { value, reversed }: { value: any; reversed?: boolean },
      searchParam: string | null
    ) => {
      dispatch({ type: "set", payload: { id, value, reversed } });
      setUrlSearchParams({ [id]: searchParam });
    },
    [setUrlSearchParams]
  );

  return (
    <>
      {tableFilters.map(({ id, Filter: FilterEl }) => (
        <FilterEl
          key={id}
          {...getFilterProps?.(id)}
          filterId={id}
          filterValue={filters.find((filter) => filter.id === id)?.value}
          urlSearchParam={urlSearchParams.get(id)}
          setFilterState={setFilterState}
        />
      ))}
    </>
  );
};

export default RemoteFilterViews;
