export interface HttpError<
  T extends string = string,
  D = T extends "text" ? string : T extends "json" ? any : Blob,
> extends Error {
  status: number;
  type: T;
  data: D;
}

export type NetworkError = Error;

export type RequestError = NetworkError | HttpError;

export function isHttpError(error: any): error is HttpError {
  return typeof error?.status === "number";
}

export async function http<T = unknown>(path: string, options?: RequestInit): Promise<T> {
  const response = await fetch(path, options);

  let type = response.headers.get("Content-Type");
  let data: unknown;
  try {
    if (response.status === 204) {
      data = null;
    } else if (type?.match(/text\/plain/)) {
      type = "text";
      data = await response.text();
    } else if (type?.match(/[/+]json/)) {
      type = "json";
      data = await response.json();
    } else {
      data = await response.blob();
    }
  } catch (validationError) {
    throw Object.assign(validationError as Error, { status: response.status, type });
  }

  if (!response.ok) {
    throw Object.assign(new Error(`HTTP Error: ${response.status} ${response.statusText}`), {
      status: response.status,
      type,
      data,
    });
  }

  return data as T;
}

export function httpWithAuth<T = unknown>(token: string, path: string, options?: RequestInit) {
  const headers = new Headers(options?.headers);
  headers.set("Authorization", `Bearer ${token}`);
  if (!options) {
    options = { headers };
  } else {
    options.headers = headers;
  }
  return http<T>(path, options);
}
