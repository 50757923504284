import { Box, Theme } from "@mui/material";
import { BoxTypeMap, SxProps } from "@mui/system";
import { ComponentPropsWithRef, ElementRef, ForwardedRef, ReactNode, forwardRef } from "react";
import Breadcrumbs, { BreadcrumbsProps } from "./Breadcrumbs";
import SideBar from "./SideBar";
import SideBarV2, { SideBarOption } from "./SideBarV2";
import useStorageState from "./utils/useStorageState";

const closedSidebarWidth = 56;
const openedSidebarWidth = 240;

function capitalize<T extends string>(str: T) {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
}

interface PageBaseProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  breadcrumbs?: boolean;
  breadcrumbNameMap?: BreadcrumbsProps["breadcrumbNameMap"];
  postfix?: ReactNode;
  sidebarOpen?: boolean;
  sidebarDefaultOpen?: boolean;
  onSidebarOpen?: () => void;
  onSidebarClose?: () => void;
  sidebarOptions?: SideBarOption[];
  sidebarWidth?: number;
  sidebarFooter?: ReactNode;
  sidebarAnchor?: "left" | "right";
  sidebarHeader?: ReactNode;
  sidebarVariant?: "permanent" | "persistent" | "temporary" | undefined;
  sidebarMenuButtonContainer?: Element;
  backLink?: string;
  crumbSx?: SxProps<Theme>;
  sidebarVersion?: "v1" | "v2";
}

export type PageProps<D extends React.ElementType = BoxTypeMap["defaultComponent"]> =
  PageBaseProps &
    Omit<ComponentPropsWithRef<D>, keyof PageBaseProps> & {
      component?: D;
    };

const Page = <D extends React.ElementType = BoxTypeMap["defaultComponent"]>(
  {
    children,
    sx = [],
    breadcrumbs,
    breadcrumbNameMap,
    postfix,
    sidebarOptions,
    sidebarFooter,
    sidebarAnchor = "left",
    sidebarWidth = openedSidebarWidth,
    sidebarOpen,
    onSidebarOpen,
    onSidebarClose,
    sidebarDefaultOpen = false,
    sidebarHeader,
    sidebarVariant,
    sidebarMenuButtonContainer,
    backLink,
    crumbSx,
    sidebarVersion,
    ...rest
  }: PageProps<D>,
  ref: ForwardedRef<ElementRef<D>>
) => {
  const [sbOpen, setSbOpen] = useStorageState("sbOpen", sidebarDefaultOpen);

  const handleSidebarOpen = () => {
    if (sidebarOpen === undefined || sidebarOpen === null) {
      setSbOpen(true);
    }
    onSidebarOpen?.();
  };

  const handleSidebarClose = () => {
    if (sidebarOpen === undefined || sidebarOpen === null) {
      setSbOpen(false);
    }
    onSidebarClose?.();
  };

  const isOpen = sidebarOpen ?? sbOpen;
  const marginKey = `margin${capitalize(sidebarAnchor)}` as const;
  const marginValue =
    sidebarVariant === "temporary" ? "0" : isOpen ? `${sidebarWidth}px` : `${closedSidebarWidth}px`;

  const sidebarProps = {
    width: sidebarWidth,
    options: sidebarOptions ?? [],
    footer: sidebarFooter,
    anchor: sidebarAnchor,
    open: isOpen,
    header: sidebarHeader,
    onSidebarOpen: handleSidebarOpen,
    onSidebarClose: handleSidebarClose,
    backLink: backLink,
    closedWidth: closedSidebarWidth,
    variant: sidebarVariant,
    buttonContainer: sidebarMenuButtonContainer,
  };

  return (
    <>
      {sidebarOptions &&
        (sidebarVersion === "v1" ? <SideBar {...sidebarProps} /> : <SideBarV2 {...sidebarProps} />)}
      <Box
        {...rest}
        sx={[
          {
            bgcolor: "background.default",
            position: "relative",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowY: "auto",
            padding: 1.5,
          },
          !!sidebarOptions && { [marginKey]: marginValue },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        ref={ref}
      >
        {(breadcrumbs || postfix) && (
          <Box sx={{ display: "flex", alignItems: "center", margin: 1, minHeight: "48px" }}>
            {breadcrumbs && <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} crumbSx={crumbSx} />}
            <Box sx={{ flex: 1 }} />
            {postfix}
          </Box>
        )}
        {children}
      </Box>
    </>
  );
};

export default forwardRef(Page);
