import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router";

const useURLSearchParams = (): [
  URLSearchParams,
  (values: Record<string, string | null>) => void,
] => {
  const { replace } = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  // the setSearchParams callback should not depend on the useLocation hook!
  const searchParamsRef = useRef(searchParams);
  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const setSearchParams = useCallback(
    (values: Record<string, string | null | undefined>) => {
      const { current } = searchParamsRef;
      for (const [key, value] of Object.entries(values)) {
        if (value) {
          current.set(key, value);
        } else {
          current.delete(key);
        }
      }
      current.sort();
      replace({ search: current.toString() });
    },
    [replace]
  );

  return [searchParams, setSearchParams];
};

export default useURLSearchParams;
