import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";

export type TextFieldProps = MuiTextFieldProps & { readOnly?: boolean };

const TextField = ({ InputProps, focused, variant, readOnly, ...rest }: TextFieldProps) => (
  <MuiTextField
    variant={variant ?? "filled"}
    InputLabelProps={{ shrink: true }}
    InputProps={{
      ...InputProps,
      readOnly,
    }}
    focused={readOnly ? false : focused}
    {...rest}
  />
);

export default TextField;
