import { BatchOp } from "@mb-pro/jsonapi-diff/types";
import { useCallback } from "react";
import { useMutation, UseMutationOptions } from "react-query";
import { useApi, useBackendSchemas } from "../query";
import { TypeCategories } from "../types";
import { JsonapiError } from "./types";
import { transformValues } from "./utils";

type UseBatchedChangesOptions = UseMutationOptions<any, JsonapiError, BatchOp[]> & {
  schemasTimeout?: number;
};

export default function useBatchedChanges(
  category: TypeCategories,
  { schemasTimeout = 5000, ...options }: UseBatchedChangesOptions = {}
) {
  const api = useApi();
  const { waitFor } = useBackendSchemas();

  const batchChanges = useCallback(
    async (changes: BatchOp[]) => {
      const schemas = await waitFor(schemasTimeout);

      const catSchemas = schemas[category];
      if (!catSchemas) {
        throw new Error(`Backend schemas are not available for category: ${category}`);
      }

      return api<any>(`/${category}/${changes[0].type}`, {
        method: "post",
        headers: {
          Accept: "application/vnd.api+json, application/json",
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify({
          batch: changes.map((op) => {
            const schema = catSchemas[op.type];
            if (!schema) {
              throw new Error(`Backend schema is not available for type: ${category}/${op.type}`);
            }

            return transformValues(schema, op);
          }),
        }),
      });
    },
    [api, category, waitFor, schemasTimeout]
  );

  return useMutation<any, JsonapiError, BatchOp[]>(batchChanges, options);
}
