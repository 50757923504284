import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";

interface SocketIOContextValue {
  sockets: Record<string, Socket>;
  enabled: boolean;
}

const SocketIOContext = createContext<SocketIOContextValue | undefined>(undefined);

type SocketIOProviderProps = PropsWithChildren<{
  url: string;
  enabled?: boolean;
  namespaces?: string[];
  options?: Partial<ManagerOptions & SocketOptions>;
}>;

function setupSockets(
  enabled: boolean,
  url: string,
  namespaces: string[] | undefined,
  options?: Partial<ManagerOptions & SocketOptions>
) {
  if (!enabled) {
    return { enabled, sockets: {} };
  }

  const sockets: Record<string, Socket> = {};
  if (namespaces && namespaces.length > 0) {
    for (const namespace of namespaces) {
      sockets[namespace] = io(url.endsWith("/") ? url + namespace : url + `/${namespace}`, options);
    }
  } else {
    sockets.default = io(url, options);
  }

  return { enabled, sockets };
}

export default function SocketIOProvider(props: SocketIOProviderProps) {
  const { children, namespaces, url, options, enabled = true } = props;
  const [socketsCtx, setSocketCtx] = useState<SocketIOContextValue>({
    enabled: false,
    sockets: {},
  });

  useEffect(() => {
    setSocketCtx((state) => {
      for (const socket of Object.values(state.sockets)) {
        socket.close();
      }
      return setupSockets(enabled, url, namespaces, options);
    });
  }, [enabled, url, namespaces, options]);

  return <SocketIOContext.Provider value={socketsCtx}>{children}</SocketIOContext.Provider>;
}

export function useNamespace(namespace: string = "default") {
  const socketsCtx = useContext(SocketIOContext);
  if (socketsCtx === undefined) {
    throw new Error("useSockets must be used within a SocketIOProvider");
  }

  const socket = socketsCtx.sockets[namespace] as Socket | undefined;
  if (socketsCtx.enabled && !socket) {
    throw new Error(`The namespace "${namespace}" isn't valid`);
  }

  return socket;
}
