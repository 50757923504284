import { Box, styled, SxProps, TableHead, TableRow, Theme, Typography } from "@mui/material";
import { HeaderGroup } from "react-table";
import getCustomColumnProps from "./getCustomColumnProps";

export const FooterCellContentWrapper = styled(Typography)(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(0, 1.5, 0, 2),
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const TableFooter = <Type extends object = {}>({
  footerGroups,
  sx = [],
}: {
  footerGroups: HeaderGroup<Type>[];
  sx?: SxProps<Theme>;
}) => (
  <TableHead
    component="div"
    sx={[
      {
        display: "flex",
        alignItems: "center",
        "@media (min-width: 600px)": {
          minHeight: "unset",
          height: "30px",
        },
        "@media (min-width: 0px) and (orientation: landscape)": {
          minHeight: "unset",
          height: "30px",
        },
        bgcolor: "background.default",
        zIndex: 1,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {footerGroups.map(({ getFooterGroupProps, headers }) => (
      //eslint-disable-next-line react/jsx-key
      <TableRow
        {...getFooterGroupProps()} // includes a `key` prop
        component="div"
        sx={{
          bgcolor: "transparent",
        }}
      >
        {headers.map((column, index) => {
          return (
            //eslint-disable-next-line react/jsx-key
            <Box
              {...column.getFooterProps(getCustomColumnProps(column))} // includes a `key` prop
              sx={[
                index !== headers.length - 1 && {
                  borderRightWidth: "1px",
                  borderRightStyle: "solid",
                  borderRightColor: "divider",
                },
              ]}
            >
              <Box sx={{ display: "flex", maxWidth: "100%" }}>
                <FooterCellContentWrapper noWrap>
                  {column.render("Footer")}
                </FooterCellContentWrapper>
              </Box>
            </Box>
          );
        })}
      </TableRow>
    ))}
  </TableHead>
);

export default TableFooter;
