import { IJSONObject } from "@mb-pro-ui/jsonapi-types";
import { useCallback } from "react";
import { useMutation, UseMutationOptions } from "react-query";
import { useApi, useBackendSchema } from "../query";
import { BaseType, ID, Type } from "../types";
import { JsonapiError } from "./types";
import { transformValues } from "./utils";

type UpdateParams = IJSONObject & { id: ID };

type UseUpdateOptions<T> = UseMutationOptions<T, JsonapiError, UpdateParams> & {
  schemaTimeout?: number;
};

export default function useUpdate<T extends BaseType>(
  fullType: Type<T["type"]>,
  { schemaTimeout = 5000, ...options }: UseUpdateOptions<T> = {}
) {
  const api = useApi();
  const { waitFor } = useBackendSchema(fullType);

  const updateItem = useCallback(
    async (data: UpdateParams) => {
      const schema = await waitFor(schemaTimeout);

      return api<T>(`/${fullType}/${data.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/vnd.api+json, application/json",
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify({ data: transformValues(schema, data) }),
      });
    },
    [api, fullType, waitFor, schemaTimeout]
  );

  return Object.assign(useMutation<T, JsonapiError, UpdateParams>(updateItem, options), {
    authenticated: api.authenticated,
  });
}
